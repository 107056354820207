import styled from "styled-components";



export const ErrorText = styled.span`
  
  position: fixed;
  top: 50px; /* Adjust as needed */
  //right: 10px; /* Adjust as needed */
  background-color: #f8d7da; /* Background color for error */
  color: #721c24; /* Text color for error */
  padding: 10px 20px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  //z-index: 1000; /* Ensure it appears on top */
`;

