import * as React from "react";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorText } from "../commons";
import axios, { AxiosError } from "axios";
import { DatePickerProps, Form, Input } from "antd";
import { DatePicker } from "antd";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { toast } from 'react-toastify';

interface Country {
  name: string;
  code: string;
}

const countries: Country[] = [{name: 'Select your country',code:""},{ name: 'Albania', code: '+355 ' },
  { name: 'Algeria', code: '+213 ' },{ name: 'American Samoa', code: '+1684 ' },{ name: 'Andorra', code: '+376 ' },
  { name: 'Angola', code: '+244 ' },{ name: 'Anguilla', code: '+1264 ' },{ name: 'Antarctica', code: '+672 ' },
  { name: 'Antigua and Barbuda', code: '+1-268 ' },
  { name: 'Argentina', code: '+54 ' },{ name: 'Armenia', code: '+374 ' },{ name: 'Aruba', code: '+297 ' },
  { name: 'Ascension Island', code: '+247 ' },{ name: 'Australia', code: '+61 ' },{ name: 'Austria', code: '+43 ' },
  { name: 'Azerbaijan', code: '+994 ' },{ name: 'Bahamas', code: '+973 ' },{ name: 'Bahrain', code: '+973 ' },
  { name: 'Bangladesh', code: '+880 ' }, { name: 'Barbados', code: '+1-246 ' },{ name: 'Belarus', code: '+375 ' },
  { name: 'Belgium', code: '+32 ' },{ name: 'Belize', code: '+501 ' },{ name: 'Benin', code: '+229 ' },
  { name: 'Bermuda', code: '+1-441 ' },{ name: 'Bhutan', code: '+975 ' },
  { name: 'Bolivia ', code: '+591 ' },{ name: 'Bosnia and Herzegovina', code: '+387 ' },{ name: 'Botswana', code: '+267 ' },
  { name: 'Brazil', code: '+55 ' }, { name: 'Brunei', code: '+673 ' },{ name: 'Bulgaria', code: '+359 ' },
  { name: 'Burkina Faso', code: '+226 ' },{ name: 'Burundi', code: '+257 ' }, { name: 'Cambodia', code: '+855 ' },
  { name: 'Cameroon', code: '+237 ' },{ name: 'Canada', code: '+1 ' },{ name: 'Cape Verde', code: '+238 ' },
  { name: 'Cayman Islands', code: '+1345 ' }, { name: 'Central African Republic', code: '+236 ' },{ name: 'Chad', code: '+235 ' },
  { name: 'Chile', code: '+56 ' },{ name: 'China', code: '+86 ' }, { name: 'Colombia', code: '+57 ' },
  { name: 'Comoros', code: '+269 ' },{ name: 'Congo', code: '+242 ' },{ name: 'Congo(DRC)', code: '+243 ' },
  { name: 'Cook Islands', code: '+682 ' }, { name: 'Costa Rica', code: '+506 ' },{ name: 'Croatia', code: '+385 ' },
  { name: 'Cuba', code: '+53 ' }, { name: 'Cyprus', code: '+357 ' },{ name: 'Czech Republic', code: '+420 ' },
  { name: 'Denmark', code: '+45 ' },{ name: 'Diego Garcia', code: '+246 ' },{ name: 'Djibouti', code: '+253 ' },
  { name: 'Dominican Republic', code: '+1 ' },
  { name: 'East Timor', code: '+670 ' },{ name: 'Ecuador', code: '+593 ' },{ name: 'Egypt', code: '+20 ' },
  { name: 'ElSavador', code: '+503 ' },{ name: 'Equatorial Guinea', code: '+240 ' },{ name: 'Eritrea', code: '+291 ' },
  { name: 'Estonia', code: '+372 ' }, { name: 'Eswatini', code: '+268 ' },{ name: 'Ethiopia', code: '+251 ' },
  { name: 'Faroe Islands', code: '+298 ' },{ name: 'Fiji Islands', code: '+679 ' },{ name: 'Finland', code: '+358 ' },
  { name: 'France', code: '+33 ' }, { name: 'Gabon', code: '+241 ' },{ name: 'Gambia', code: '+220 ' },
  { name: 'Georgia', code: '+995 ' },{ name: 'Germany', code: '+49 ' },{ name: 'Ghana', code: '+233 ' },
  { name: 'Gibraltar', code: '+350 ' },{ name: 'Greece', code: '+30 ' },{ name: 'Greenland', code: '+299 ' },
  { name: 'Grenada', code: '+1-473 ' },{ name: 'Guadeloupe', code: '+590 ' },{ name: 'Guam', code: '+1-671 ' },
  { name: 'Guatemala', code: '+502 ' },{ name: 'Guinea', code: '+224 ' },
  { name: 'Guinea-Bissau', code: '+245 ' },{ name: 'Guyana', code: '+592 ' },{ name: 'Haiti', code: '+509 ' },
  { name: 'Honduras', code: '+504 ' },{ name: 'Hungary', code: '+36 ' },{ name: 'Iceland', code: '+354 ' },
  { name: 'India', code: '+91 ' }, { name: 'Indonesia', code: '+62 ' },{ name: 'Iran', code: '+98 ' },
  { name: 'Iraq', code: '+964 ' },{ name: 'Ireland', code: '+353 ' },{ name: 'Israel', code: '+972 ' },
  { name: 'Italy', code: '+39 ' },
  { name: 'Ivory Coast', code: '+225 ' },{ name: 'Jamaica', code: '+1 ' },{ name: 'Japan', code: '+81 ' },
  { name: 'Jordan', code: '+962 ' },{ name: 'Kazakhstan', code: '+7 ' },{ name: 'Kenya', code: '+254 ' },
  { name: 'Korea,South', code: '+82 ' },{ name: 'Kosovo', code: '+383 ' },{ name: 'Kuwait', code: '+965 ' },
  { name: 'Kyrgyzstan', code: '+996 ' },{ name: 'Laos', code: '+856 ' },{ name: 'Latvia', code: '+371 ' },
  { name: 'Lebanon', code: '+961 ' },{ name: 'Lesotho', code: '+266 ' },
  { name: 'Liberia', code: '+231 ' },{ name: 'Libya', code: '+218 ' },{ name: 'Liechtenstein', code: '+423 ' },
  { name: 'Lithuania', code: '+370 ' },{ name: 'Luxembourg', code: '+352 ' },
  { name: 'Madagascar', code: '+261 ' },{ name: 'Malawi', code: '+265 ' },{ name: 'Malaysia', code: '+60 ' },
  { name: 'Maldives', code: '+960 ' },{ name: 'Mali', code: '+223 ' },{ name: 'Malta', code: '+356 ' },
  { name: 'Marshall Islands', code: '+692 ' },{ name: 'Martinique', code: '+596 ' },
  { name: 'Mauritania', code: '+222 ' },{ name: 'Mauritius', code: '+230 ' },{ name: 'Mexico', code: '+52 ' },
  { name: 'Moldova', code: '+373 ' },{ name: 'Mongolia', code: '+976 ' },{ name: 'Montenegro', code: '+382 ' },
  { name: 'Morocco', code: '+212 ' },
  { name: 'Mozambique', code: '+258 ' },{ name: 'Myanmar', code: '+95 ' },{ name: 'Namibia', code: '+264 ' },
  { name: 'Nauru', code: '+674 ' }, { name: 'Nepal', code: '+977 ' },{ name: 'Netherlands', code: '+31 ' },
  { name: 'New Zealand', code: '+64 ' },{ name: 'Nicaragua', code: '+505 ' },{ name: 'Niger', code: '+227 ' },
  { name: 'Nigeria', code: '+234 ' },{ name: 'Norway', code: '+47 ' },{ name: 'North Macedonia', code: '+389 ' },
  { name: 'Oman', code: '+968 ' }, { name: 'Pakistan', code: '+92 ' },{ name: 'Panama', code: '+507 ' },
  { name: 'Papua New Guinea', code: '+675 ' },{ name: 'Paraguay', code: '+595 ' },
  { name: 'Peru', code: '+51 ' },{ name: 'Philippines', code: '+63 ' },{ name: 'Poland', code: '+48 ' },
  { name: 'Portugal', code: '+351 ' },{ name: 'Puerto Rico', code: '+1-787 ' },{ name: 'Qatar', code: '+974 ' },
  { name: 'Romania', code: '+40 ' },{ name: 'Russia', code: '+7 ' },
  { name: 'Rwanda', code: '+250 ' },{ name: 'Saint Kitts and Nevis', code: '+1-869 ' },{ name: 'Saint Lucia', code: '+1-758 ' },
  { name: 'Saint Vincent and the Grenadines', code: '+1-784 ' },{ name: 'Samoa', code: '+685 ' },{ name: 'San Marino', code: '+378 ' },
  { name: 'Sao Tome and Principe', code: '+239 ' },{ name: 'Saudi Arabia ', code: '+966' },{ name: 'Senegal', code: '+221 ' },
  { name: 'Serbia', code: '+381 ' },{ name: 'Seychelles', code: '+248 ' },{ name: 'Sierra Leone', code: '+232 ' },
  { name: 'Singapore', code: '+65 ' },{ name: 'Slovakia', code: '+421 ' },{ name: 'Slovenia', code: '+386 ' },
  { name: 'Solomon Islands', code: '+677 ' },{ name: 'Somalia', code: '+252 ' },{ name: 'South Africa', code: '+211 ' },
  { name: 'South Sudan', code: '+211 ' },{ name: 'Spain', code: '+34 ' },{ name: 'Sri Lanka', code: '+94 ' },
  { name: 'Sudan', code: '+249 ' }, { name: 'Suriname', code: '+597 ' },{ name: 'Swaziland', code: '+41 ' },
  { name: 'Sweden', code: '+46 ' },{ name: 'Taiwan', code: '+886 ' },{ name: 'Tajikistan', code: '+992 ' },
  { name: 'Tanzania', code: '+255 ' },{ name: 'Thailand', code: '+66 ' },{ name: 'Togo', code: '+228 ' },
  { name: 'Tonga', code: '+676 ' },{ name: 'Trindad and Tobago', code: '+1-868 ' },{ name: 'Tunisia', code: '+216 ' },
  { name: 'Turkey', code: '+90 ' },{ name: 'Turkmenistan', code: '+993 ' },{  name: 'Uganda', code: '+256 ' },
  { name: 'Ukraine', code: '+380 ' },{ name: 'UAE', code: '+971 ' },{ name: 'Uruguay', code: '+598 ' },
  { name: 'Uzbekistan', code: '+998 ' },{ name: 'Venezuela', code: '+58 ' },{ name: 'Vietnam', code: '+84 ' },
  { name: 'Zambia', code: '+260 ' }, { name: 'Zimbabwe', code: '+263 ' }

  // Add more countries as needed
];

//
// const userSignUpRequest = {
//   firstName: "",
//   lastName: "",
//   email: "",
//   dob: "",
//   password: "",
//   confirmPassword: "",
//   address: "",
//   phoneNumber: "",
//   gender: "",
//
// };

const googleMapsApiKey: string = process.env
  .REACT_APP_GOOGLE_MAPS_API_KEY as string;
const libraries: any = ["drawing", "places"];

function SignupPage(props: any) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries,
  });


  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<Country>(countries[0]);

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const country = countries.find(c => c.name === e.target.value);
    if (country) {
      setSelectedCountry(country);
      setPhoneNumber(country.code);
    }
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(selectedCountry.code, '');
    setPhoneNumber(selectedCountry.code + inputValue);
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    if (typeof dateString === 'string') {
      setDob(dateString);
    } else if (Array.isArray(dateString) && dateString.length > 0) {
      setDob(dateString[0]); // Or handle this case differently based on your needs
    }
    console.log(date, dateString);
  };

  const loginPage = () => {
    navigate("/");
  };



  const saveUser = async () => {


    try {

      await form.validateFields();
      if (
                  firstName && lastName && email && password &&
                  confirmPassword && selectedCountry?.name &&
                  phoneNumber && gender && dob
              ) {
        const userSignUpRequest = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          confirmPassword: confirmPassword,
          address: selectedCountry.name,
          phoneNumber: phoneNumber,
          gender: gender,
          dob: dob,
        };

        const response = await axios.post(
            "https://eurolotto-0b7e41150561.herokuapp.com/api/v1/user/signup",
            userSignUpRequest
        );

        console.log(response.data);
        if (response.data?.responseMessage === "Registration Successful") {
          toast.success("Email verification has been sent to " + email + ".");
          setTimeout(() => {
            loginPage(); // Redirect after toast notification
          }, 2000); // Adjust delay as needed
        } else {
          toast.error(response.data.responseMessage);
        }

        loginPage();
      }else {
        setError("Please, Fill all the required fields");
        setTimeout(() => {
          setError("");
        }, 5000);
            }
    } catch (err) {
      if (err && err instanceof AxiosError) {
        setError(err.response?.data.responseMessage);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
      else if (err && err instanceof Error)
        setError(err.message);

      console.log("Error: ", err);
    }
  };





  // const saveUser = async () => {
  //   try {
  //     // Validate the form fields
  //     await form.validateFields();
  //
  //     if (
  //         firstName && lastName && email && password &&
  //         confirmPassword && selectedCountry?.name &&
  //         phoneNumber && gender && dob
  //     ) {
  //       const userSignUpRequest = {
  //         firstName: firstName,
  //         lastName: lastName,
  //         email: email,
  //         password: password,
  //         confirmPassword: confirmPassword,
  //         address: selectedCountry.name,
  //         phoneNumber: phoneNumber,
  //         gender: gender,
  //         dob: dob,
  //       };
  //
  //       const response = await axios.post(
  //           "https://eurolotto-0b7e41150561.herokuapp.com/api/v1/user/signup",
  //           userSignUpRequest
  //       );
  //
  //       // Check if the responseMessage indicates success
  //       if (response.data?.responseMessage === "Registration Successful") {
  //         console.log(response.data);
  //         alert("Email verification has been sent to " + email + ".");
  //
  //         // Redirect to login page on successful signup
  //         loginPage();
  //       } else {
  //         throw new Error("Unexpected response structure");
  //       }
  //     } else {
  //       throw new Error("Please fill in all required fields.");
  //     }
  //   } catch (err) {
  //     if (err && err instanceof AxiosError) {
  //       console.log("Axios error: ", err.response);
  //
  //       // Use responseMessage from error response if available
  //       setError(err.response?.data?.responseMessage || "An error occurred");
  //     } else if (err && err instanceof Error) {
  //       console.log("General error: ", err);
  //       setError(err.message);
  //     } else {
  //       console.log("Unknown error: ", err);
  //       setError("An unknown error occurred");
  //     }
  //
  //     setTimeout(() => {
  //       setError("");
  //     }, 5000);
  //
  //     console.log("Error: ", err);
  //   }
  // };


  return (
       
      <div className=" min-h-screen flex items-center justify-center bg-cover bg-center"
           style={{ backgroundImage: "url('eurolotto.jpeg')" }}>

      <div className="md:container md:mx-auto px-8 py-20 md:w-8/12 lg:w-5/12">
        <div className="items-center shadow-lg border-2 border-black border-solid flex flex-col px-8 rounded-2xl max-md:px-5">
        <div className="text-white text-lg font-bold  mt-[10%]">
                Create a new account{" "}
              </div>
              <Form
                  form={form}
                  name="signup"
                  scrollToFirstError
                  onFinish={saveUser}
                  className="md:w-full md:flex md:flex-col md:items-stretch"
                  autoComplete="off"
              >
                <div className="mt-7">
                  {error && <ErrorText>{error}</ErrorText>}
                  <label
                      className="block uppercase text-left w-full tracking-wide text-white text-xs font-bold mb-2 mt-2"
                      htmlFor="grid-first-name"
                  >
                    First Name
                  </label>
                  <Form.Item
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First name!",
                        },
                      ]}
                  >
                    <Input

                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        maxLength={20}
                    />
                  </Form.Item>
                </div>
                <div>
                  <label
                      className="block uppercase text-left w-full tracking-wide text-white text-xs font-bold mb-2 mt-1"
                      htmlFor="grid-last-name"
                  >
                    Last Name
                  </label>
                  <Form.Item
                      name="lastName"

                      rules={[
                        {
                          required: true,
                          message: "Please input your Last name!",
                        },
                      ]}
                  >
                    <Input

                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="grid-last-name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        maxLength={20}
                    />
                  </Form.Item>
                </div>



                  <label
                      className="block uppercase text-left w-full tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-email"
                  >
                    Email
                  </label>
                  <Form.Item
                      name="email"
                      // className="w-full px-3 mb-1"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                  >
                    <Input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white"
                        id="grid-email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        maxLength={50}
                    />
                  </Form.Item>

                  <label
                      className="block uppercase text-left w-full tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-password"
                  >
                    Password
                  </label>

                  <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        () => ({
                          validator(_, value) {
                            if ((value).toString().length >= 4) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    "Password is too short!"
                                )
                            );
                          },
                        }),
                      ]}
                      hasFeedback
                  >
                    <Input.Password
                        className="py-3 w-full px-3 "
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        maxLength={20}
                    />
                  </Form.Item>

                  <label
                      className="block uppercase text-left w-full tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-confirm-password"
                  >
                    Confirm Password
                  </label>

                  <Form.Item
                      name="confirmPassword"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error(
                                    "The new password that you entered do not match!"
                                )
                            );
                          },
                        }),
                      ]}
                  >
                    <Input.Password
                        className="py-3 w-full px-3 border-solid"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                  </Form.Item>






                <div className="flex -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                    <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="address">
                      Country
                    </label>
                    <select className="block w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="address"
                            value={selectedCountry.name}
                            onChange={handleCountryChange}
                            required
                    >
                      {countries.map((country) => (
                          <option key={country.name} value={country.name} disabled={country.code === ""}>
                            {country.name}
                          </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-white text-xs font-bold mb-2" htmlFor="grid-phone">
                      Phone Number
                    </label>
                    <Input className="appearance-none block w-full border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                           id="grid-phone"
                           type="tel"
                           value={phoneNumber}
                           onChange={handlePhoneNumberChange}
                           required
                           maxLength={20}
                    />
                  </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 relative">
                    <label
                        className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                        htmlFor="grid-gender"
                    >
                      Gender
                    </label>
                    <div className="relative">
                      <select
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-gender"
                          onChange={(e) => setGender(e.target.value)}
                          required
                      >
                        <option value=""  >Select gender</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 ">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 px-3">
                    <label
                        className="block uppercase tracking-wide text-white  text-xs font-bold mb-2"
                        htmlFor="grid-date"
                    >
                      Date of Birth
                    </label>
                    <div>
                      <DatePicker
                          className="w-full md:w-9/8 px-3 py-2.5 "
                          onChange={onChange}

                      />
                    </div>
                  </div>
                </div>

                <div className="items-stretch self-stretch flex flex-col my-4 max-md:max-w-full">
                  <button
                      className="bg-green-500 text-green-200  text-sm font-semibold leading-5 whitespace-nowrap justify-center items-center  px-5 py-3 rounded-lg max-md:max-w-full hover:text-black hover:bg-green-600"
                      type="button"
                      onClick={saveUser}
                  >
                    Sign Up
                  </button>
                </div>

              </Form>
              <div className="text-sm leading-5  self-center whitespace-normal mt-5 mb-5 md:flex md:flex-col items-stretch">
                <span className=" text-white">Already have an account ? </span>
                <a
                    href="/"
                    className="font-semibold text-white hover:text-green-600 underline"
                >
                  Sign in here
                </a>
              </div>
            </div>
          </div>

      </div>
  );
}

export default SignupPage;
