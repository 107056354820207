import "./App.css";
import { Routes, Route } from "react-router-dom";
import { RequireAuth } from "react-auth-kit";
import SignupPage from "./user/SignupPage";
import ResetPasswordEmail from "./user/ResetPasswordEmail";
import ResetPassword from "./user/ResetPassword";

import AdminLogin from "./admin/AdminLogin";
import AdminSignup from "./admin/AdminSignup";
import UserPage from "./user/UserPage";
import { ToastContainer } from "react-toastify";

import AdminPage from "./admin/AdminPage";
import LoginPage from "./user/LoginPage";
import AdminResetPassword from "./admin/AdminResetPassword";
import AdminResetPasswordEmail from "./admin/AdminResetPasswordEmail";
import EmailVerificationMessage from "./user/EmailVerificationMessage";
import AdminEmailVerificationMessage from "./admin/AdminEmailVerificationMessage";
import AboutUs from "./utils/AboutUs";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path="/" element={<LoginPage />}></Route>
        <Route path="/signup" element={<SignupPage />}></Route>
        <Route path="/reset_password" element={<ResetPasswordEmail />}></Route>
        <Route
          path="/home"
          element={
            <RequireAuth loginPath="/">
              <UserPage />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/about"
          element={
            <RequireAuth loginPath="/">
              <AboutUs />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/reset_password/email_verification"
          element={<ResetPassword />}
        ></Route>
        <Route
          path="/registration/email_verification"
          element={<EmailVerificationMessage />}
        ></Route>

        <Route path="/admin/signup" element={<AdminSignup />}></Route>
        <Route path="/admin" element={<AdminLogin />}></Route>
        <Route
          path="/admin/home"
          element={
            <RequireAuth loginPath="/admin">
              <AdminPage />
            </RequireAuth>
          }
        ></Route>

        <Route
          path="admin/reset_password"
          element={<AdminResetPasswordEmail />}
        ></Route>
        <Route
          path="/admin/reset_password/email_verification"
          element={<AdminResetPassword />}
        ></Route>
        <Route
          path="/registration/admin/email_verification"
          element={<AdminEmailVerificationMessage />}
        ></Route>
      </Routes>
    </div>
  );
}

export default App;
