import React, { useEffect } from "react";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { Form, Input } from "antd";
import Modal from "react-modal";
import {ErrorText} from "../commons";

const resetPasswordRequest = {
  newPassword: "",
  confirmPassword: "",
};

function AdminResetPassword(props: any) {

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [form] = Form.useForm();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inputDisabled, setInputDisabled] = useState(false);

  const email = localStorage.getItem("resetPasswordEmail");

  const loginPage = () => {
    navigate("/admin");
  };

  function closeModal() {
    setIsOpen(false);
    setError("");
    loginPage();
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setInputDisabled(true);
      localStorage.removeItem("resetPasswordEmail");
      loginPage();
    }, 600000); // 10 minutes in milliseconds
    return () => clearTimeout(timer); // Cleanup on unmount
  }, [navigate]);

  const passwordReset = async () => {

    if (inputDisabled) return;

    resetPasswordRequest.newPassword = password;
    resetPasswordRequest.confirmPassword = confirmPassword;


    try {
      const response = await axios.post(
        "https://eurolotto-0b7e41150561.herokuapp.com/api/v1/reset_password/newPassword?email="+email,
        resetPasswordRequest

      );

      console.log(response.data);

      setIsOpen(true);
    } catch (err) {
      if (err && err instanceof AxiosError)
        setError(err.response?.data?.responseMessage);
      else if (err && err instanceof Error) setError(err.message);

      console.log("Error: ", err);
    }
  };

  return (
    <>
      <div className=" bg-slate-600 min-h-screen">


          <div className="md:container md:mx-auto px-8 py-20 md:w-8/12 lg:w-5/12">
            <div className="items-center shadow-lg bg-white flex flex-col px-8 rounded-2xl max-md:px-5">

              <div className="text-black text-2xl font-bold leading-9 whitespace-nowrap mt-6">
                Reset your password{" "}
              </div>
              <Form
                form={form}
                name="signup"
                scrollToFirstError
                onFinish={passwordReset}
                className="w-full max-w-lg"
              >
                <div className="mt-10">
                  {error && <ErrorText>{error}</ErrorText>}
                </div>

                <div>
                  <label
                    className="block uppercase text-left w-full tracking-wide text-black  text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      className="py-3 w-full px-3 "
                      id="grid-password"
                      onChange={(e) => setPassword(e.target.value)}
                      disabled={inputDisabled}
                      maxLength={20}
                    />
                  </Form.Item>

                  <label
                    className="block uppercase text-left w-full tracking-wide text-black text-xs font-bold mb-2"
                    htmlFor="grid-confirm-password"
                  >
                    Confirm Password
                  </label>

                  <Form.Item
                    name="confirmPassword"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The new password that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="py-3 w-full px-3   "
                      id="grid-confirm-password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      disabled={inputDisabled}
                    />
                  </Form.Item>
                </div>

                <div className="items-stretch self-stretch flex flex-col my-8 max-md:max-w-full">
                  <button
                    className="text-white text-sm font-semibold leading-5 whitespace-nowrap justify-center items-center  px-5 py-3 rounded-lg max-md:max-w-full bg-slate-500  hover:bg-slate-700"
                    type="button"
                    onClick={passwordReset}
                    disabled={inputDisabled}
                  >
                    Continue
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>

      <Modal
          isOpen={modalIsOpen}
          ariaHideApp={false}
          className={"w-full bg-transparent border-none"}
      >
        <div className="justify-center items-center bg-white mx-auto max-w-lg flex flex-col px-8 rounded-2xl max-md:px-5">
          <div className="text-slate-600 text-2xl justify-center items-center font-medium  whitespace-nowrap mt-8 max-md:max-w-full">
            <h3 className="m-0  text-inherit leading-[32px] font-medium font-inherit">
              Your password has been successfully reset!
            </h3>
          </div>

          <button
              className="cursor-pointer [border:none] py-5 px-[193px] rounded-[100px] bg-slate-500  w-[476px] flex flex-row items-center justify-center box-border max-md:max-w-full my-8"
              autoFocus={true}
              onClick={closeModal}
          >
            <b className=" text-white leading-[12px] font-body-bold text-text-2 text-left max-md:max-w-full">
              Continue
            </b>
          </button>
        </div>
      </Modal>
    </>
  );
}

export default AdminResetPassword;
