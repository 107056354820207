import * as React from "react";

import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { ErrorText } from "../commons";
import axios, { AxiosError } from "axios";
import { useSignIn } from "react-auth-kit";
import { Form, Input } from "antd";
import { toast } from 'react-toastify';
import "./loginPage.css"


function LoginPage(props: any) {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const signIn = useSignIn();
  const [form] = Form.useForm();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const homePage = () => {
    navigate("/home");
  };

  const loginUser = async (event: React.FormEvent) => {
    event.preventDefault();


    if (!email || !password) {
      setError("Email and password are required.");
      setTimeout(() => {
        setError("");
      }, 5000);
      return;
    }

    const userLoginRequest = {
      email: email,
      password: password
    };

    try {
      const response = await axios.post(
          "https://eurolotto-0b7e41150561.herokuapp.com/api/v1/user/login",
          userLoginRequest
      );

      const responseMessage = response.data.responseMessage;
      const data = response.data.responseData;

      if (responseMessage === "Login successfully" && data && data?.accessToken) {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("firstname", data.name || "");

        console.log("Access Token: " + data.accessToken);

        signIn({
          token: data.accessToken,
          refreshToken: data.refreshToken,
          expiresIn: 60, // minutes
          tokenType: "Bearer",
          authState: { email: email },
        });

        localStorage.setItem("email", email);
        toast.success("Login was successful");
        console.log(response.data);
        setTimeout(() => {
          homePage(); 
        }, 5000);
        
      } else {
        throw new Error(responseMessage || "Invalid response structure");
      }
    } catch (err) {
      if (err && err instanceof AxiosError) {
        console.log("Axios error: ", err.response);

        // Use responseMessage from error response if available
        setError(err.response?.data?.responseMessage || "An error occurred");
      } else if (err && err instanceof Error) {
        console.log("General error: ", err);
        setError(err.message);
      } else {
        console.log("Unknown error: ", err);
        setError("An unknown error occurred");
      }

      setTimeout(() => {
        setError("");
      }, 5000);

      console.log("Error: ", err);
    }
  };



  return (
      <div
          className="min-h-screen flex items-center justify-center bg-cover  bg-center"
          style={{
            backgroundImage:
                "url('eurolotto.jpeg')",
          }}
      >
        <div className="  border-2 border-black border-solid p-8 rounded-3xl shadow-lg z-10 w-full max-w-3xl flex container">
          <div className="w-1/2 p-4 flex flex-col mt-10 justify-between signup-container">
            <div>
              <div className="flex justify-center mb-4">
                {/* Add your SVG or Logo here */}
              </div>
              <div className="text-center">

                <p className="text-white mt-[20%] font-serif mb-2">
                  Don't Have an Account?
                </p>
                <a
                    href="/signup"
                    className="bg-green-500 text-green-200 py-2 rounded-2xl px-5 hover:text-black hover:bg-green-600"
                >
                  SIGN UP
                </a>
              </div>
            </div>
          </div>
          <div className="p-4 w-full ">
            <h2 className="text-white pr-[4%] text-2xl font-bold">Welcome Back,</h2>
            <h4 className="text-white font-extralight text-sm pr-[1%]">
              Please enter your info to log in
            </h4>
            <form className="space-y-6" onSubmit={loginUser}>
              {error && <ErrorText>{error}</ErrorText>}
              <div>
                <label htmlFor="email" className="block text-sm font-medium mt-4 text-white mr-[70%]">
                  Email
                </label>
                <input
                    type="email"
                    id="email"
                    className=" w-[100%] py-2 px-2 pl-5 text-black rounded-3xl focus:outline-none focus:ring-2 focus:ring-white "
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-white mr-[67%]">
                  Password
                </label>



                <Input.Password
                    className=" w-full py-2 px-2 text-black pl-5 rounded-3xl focus:outline-none focus:ring-2 focus:ring-white "

                    onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                  type="submit"
                  className="mr-[40%] bg-green-500 text-green-200 py-2 px-5 rounded-2xl hover:text-black hover:bg-green-600 login-button"
              >
                LOGIN {">"}
              </button>
            </form>
            <div className="flex ml-3 mt-4 justify-between items-center">
              <div className="text-sm">
                <a href="/reset_password" className="text-white hover:text-green-600 underline">
                  Forgot Password?
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default LoginPage;